const isWorkingHours = (now, workingHours) => {
  const [, sign, hoursOffset, minutesOffset] = workingHours[0]
    .split('|')[0]
    .match(/([-+])(\d{2}):(\d{2})/);

  const offset = (sign === '-' ? -1 : 1) * Number(hoursOffset) * 60 + Number(minutesOffset);
  const nowInOfficeTZ = new Date(Number(now) + offset * 60 * 1000);

  const dayOfWeek = nowInOfficeTZ.getDay();

  if (!workingHours[dayOfWeek]) return false;

  const [start, end] = workingHours[dayOfWeek]?.split('|') ?? '';
  const [hoursStart, minutesStart] = start.split(':');
  const [hoursEnd, minutesEnd] = end.split(':');
  return (
    (nowInOfficeTZ.getUTCHours() > Number(hoursStart) ||
      (nowInOfficeTZ.getUTCHours() === Number(hoursStart) &&
        nowInOfficeTZ.getUTCMinutes() >= Number(minutesStart))) &&
    (nowInOfficeTZ.getUTCHours() < Number(hoursEnd) ||
      (nowInOfficeTZ.getUTCHours() === Number(hoursEnd) &&
        nowInOfficeTZ.getUTCMinutes() < Number(minutesEnd)))
  );
};

export default isWorkingHours;
